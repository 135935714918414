@import "../main/general";
#mainPage {
  background: url(/gfx/master/bg.png);
  position: relative;
  .overbg {
    background: rgba(255,255,255,0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  #mainContent {
    position: relative;
    z-index: 1;
  }
}

    .gameLinkView {
      -webkit-box-shadow: inset 1px 1px 10px 0px rgba(203,203,203,1);
      -moz-box-shadow: inset 1px 1px 10px 0px rgba(203,203,203,1);
      box-shadow: inset 1px 1px 10px 0px rgba(203,203,203,1);
      border-radius: 5px;
      resize: none;
      color:#767676;
      width: 100%;
      height: 40px;
      z-index: 1;
      position: relative;
    }
#mainPage #mainContent {

  .tutorial {
    margin: 40px 0;
    display: none;
  }
  .card {
    text-align: center;
    .card-img {
      img {
        border: 1px solid #ff0b67;
      }
    }
    .card-footer {
      background: #ececec;
      display: inline-flex;
    }
    .card-title {
      color:#dd4981;
    }
    .card-meta {
      color:#696969;
      font-size: 0.9em;
    }
  }
  .button {
    width: 144px;
    height: 47px;
    margin: auto;
    cursor: pointer;
    &:hover {
      background-position: 0 -48px;
    }
    &.green {
      background-image: url(/gfx/master/buttonGreen.png);
      span {
        .myTextBorder1p(#617409);
      }
    }
    &.red {
      background-image: url(/gfx/master/buttonRed.png);
      span {
        .myTextBorder1p(#a51a4f);
      }
    }
    >.in {
      display: flex;
      height: 90%;
      >span {
        margin: auto;
        font-size: 16px;
        color:#ffffff;
        font-weight: bold;
      }
    }
  }




  .icon {
    background-size: contain;
    margin: auto;
    &.endlessfun {
      width: 93px;
      height: 50px;
      background: url(/gfx/master/pad.png)
    }
  }
  .setCompanyNameForm {
    display: none;
  }
}
.closeIframe {
  position: absolute;
  height: 37px;
  right: 100px;
  top: 18px;
  cursor:pointer;
  display: none;
  >span {
    font-size: 17px;
    color:#fff;
    font-weight: bold;
  }
  &:before {
    content:"";
    background: url(/gfx/ui/close.png);
    width:37px;
    height: 39px;
    position: absolute;
    left:-40px;
    top: -8px;
  }
  &:hover {
    &:before {
      background-position: 0 -38px;
    }
    >span {
      text-decoration: underline;
    }
  }
}
body.isMobile {
  .closeIframe {
    right: 0;
    top: 13px;
    z-index: 1;
    >span {
      display: none;
    }
  }
}
.badges {
  display: inline-flex;
  position: absolute;
  top: 85%;
  left: 5px;
  .badge {
    position: relative !important;
    bottom:0 !important;
    left:0 !important;
  }
}
.playGame {
  cursor: pointer;
}
.moreGames {
  border:1px solid gray;
  display: flex;
  text-align: center;
  height: 138px;
  cursor: pointer;
  >div {
    font-size: 40px;
    font-weight: bold;
    margin:auto;
  }
}

#cookieBar{
    background: rgb(159,77,113);
    background: -moz-linear-gradient(0deg, rgba(159,77,113,1) 5%, rgba(252,179,138,1) 84%);
    background: -webkit-linear-gradient(0deg, rgba(159,77,113,1) 5%, rgba(252,179,138,1) 84%);
    background: linear-gradient(0deg, rgba(159,77,113,1) 5%, rgba(252,179,138,1) 84%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9f4d71",endColorstr="#fcb38a",GradientType=1);
    position: fixed;
    left:0px;
    right: 0px;
    bottom:0px;
    min-height: 62px;
    z-index: 4;
    .flexColumnCenterAround;
    .row{
        width: 1024px;
        @media(max-width:1024px) {
          width: 100%;
        }
        .flexRowAroundCenter;
        .text{
            .myTextBorder3p(#9A5093);
            color:white;
            // width: 740px;
            font-size: 17px;
            min-height: 1px;
            @media(max-width:545px) {
              padding:5px;
            }
        }
        .formSubmit{
            background-image: url(/gfx/window_welcome/cookie/but.png);
            width: 161px;
            height: 41px;
            @media(max-width:703px) {
              margin-bottom:10px;
            }
            >.in{
                .myTextBorder3p(#00809F);
            }
        }
        .cookieImg{
            width: 85px;
            height: 51px;
            background-image: url(/gfx/window_welcome/cookie/cookie.png);
        }
    }
}
